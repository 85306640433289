import { useForm } from '@/src/hooks/form.hook';
import { countries } from '@/src/models/countries.model';
import { conditionalMinLength, createFormField, emailValidator, passwordMatchValidator, requiredValidator, yearValidator } from '@/src/models/form.model';
import { TranslationService } from '@/src/services/translation.service';
import React, { useEffect, useState } from 'react';

import { FormError } from '@/src/components/form-error/form-error';
import { LargeButton } from '@/src/components/button/button';
import * as styles from './profile-update-form.module.scss';
import { useApi } from '@/src/contexts/api.context';

const defaultDetailsForm = {
  username: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key, enabled) => {
        return <>
          <div className={styles.form__group}>
            <input
              key={key}
              id="username"
              name="username"
              placeholder={translation.translate('authentication.username')}
              type="text"
              autoComplete="username"
              value={value ? value : ''}
              onChange={onInputChange}
              className={styles.profileForm__input}
              disabled={!enabled} />
          </div>
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    validationRules: [
      requiredValidator()
    ]
  },
  email: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key, enabled) => {
        return <>
          <div className={styles.form__group}>
            <input
              key={key}
              id="email"
              name="email"
              placeholder={translation.translate('authentication.email')}
              type="text"
              autoComplete="email"
              value={value ? value : ''}
              onChange={onInputChange}
              disabled={!enabled} />
          </div>
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    validationRules: [
      requiredValidator(),
      emailValidator()
    ]
  },
  birthYear: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key, enabled) => {
        return <>
          <div className={styles.form__group}>
            <input
              key={key}
              id="birthYear"
              name="birthYear"
              placeholder={translation.translate('authentication.birth_year')}
              type="number"
              autoComplete="bday-year"
              value={value ? value : ''}
              onChange={onInputChange}
              disabled={!enabled} />
          </div>
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    validationRules: [
      yearValidator()
    ]
  },
  country: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key, enabled) => {
        return <>
          <div className={styles.form__group}>
            <select
              key={key}
              name="country"
              id="country"
              value={value ? value : ''}
              disabled={!enabled}
              onChange={event => onInputChange({ target: event.target } as any)}>
              {countries.map(country => (
                <option key={Object.keys(country)[0]} value={Object.keys(country)[0]}>{Object.values(country)[0]}</option>
              ))}
            </select>
          </div>
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    defaultValue: 'AX',
    validationRules: []
  },
  password: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key, enabled) => {
        return <>
          <input
            key={key}
            id="password"
            name="password"
            placeholder={translation.translate('forgot_password.new_password')}
            type="password"
            autoComplete="off"
            value={value ? (enabled ? value : '') : ''}
            onChange={onInputChange} />
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    validationRules: [
      conditionalMinLength(8),
    ]
  },
  repeatPassword: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key, enabled) => {
        return <>
          <input
            key={key}
            id="repeatPassword"
            name="repeatPassword"
            placeholder={translation.translate('forgot_password.new_password_repeat')}
            type="password"
            autoComplete="off"
            value={value ? (enabled ? value : '') : ''}
            onChange={onInputChange} />
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    validationRules: [
      passwordMatchValidator('password')
    ]
  }
};

export default function ProfileUpdateForm({ pageContext, detailsForm, profile, setProfile, editProfile, setEditProfile, updatedProfile }) {
  detailsForm = detailsForm || defaultDetailsForm;

  detailsForm.username.value = profile.username;
  detailsForm.country.value = profile.country;
  detailsForm.birthYear.value = profile.birthYear;
  detailsForm.email.value = profile.email;

  const apiService = useApi();
  const [translation] = useState(new TranslationService(pageContext));
  const [loading] = useState(false);
  const { renderInput, isFormValid, touchForm, form } = useForm(translation, detailsForm);
  //Set updating to stop several requests to be made?
  const [updating, setUpdating] = useState(false);

  const [formErrorMessage, setFormErrorMessage] = useState('');

  const update = (event: React.FormEvent) => {
    event.preventDefault();
    setFormErrorMessage(null);
    touchForm();
    setUpdating(true);
    return false;
  };

  const resetProfile = () => {
    form.username.value = profile.username;
    form.country.value = profile.country;
    form.birthYear.value = profile.birthYear;
    form.email.value = profile.email;
  };

  useEffect(() => {
    if(updating) {
      updateProfile();
    }
    if(!editProfile) {
      resetProfile();
    }
  }, [updating, editProfile]);

  const generateRequest = () => {
    const request = {
      username: form.username.value,
      email: form.email.value,
      birthYear: parseInt(form.birthYear.value),
      country: form.country.value,
    };
    if(form.password.value) {
      request['password'] = form.password.value;
    }
    return request;
  };

  const updateProfile = async () => {
    if(!isFormValid()) {
      setUpdating(false);
      return;
    }

    try {
      const profile = await apiService.updateProfile(generateRequest());
      setEditProfile(false);
      updatedProfile(profile);
    } catch(err) {
      setFormErrorMessage(translation.translate('profile.update_failed'));
    } finally {
      setUpdating(false);
    }
  };

  return (
    <div className={!editProfile ? styles.readonly : ''}>
      {!editProfile ? <h1> {'@' + profile.username}</h1> :
        <h1>{translation.translate('profile.edit_account')}</h1>}

      <form className={styles.profileForm} onSubmit={update} autoComplete="off">

        <div >
          <h4 className={styles.h4}>{translation.translate('profile.username')}</h4>
          <div >
            {renderInput('username', editProfile)}
          </div>
        </div>

        <div >
          <h4 className={styles.h4}>{translation.translate('profile.email')}</h4>
          <div >
            {renderInput('email', editProfile)}
          </div>
        </div>

        <div >
          <h4 className={styles.h4}>{translation.translate('authentication.country')}</h4>
          <div >
            {renderInput('country', editProfile)}
          </div>
        </div>

        <div >
          <h4 className={styles.h4}>{translation.translate('authentication.birth_year')}</h4>
          <div >
            {renderInput('birthYear', editProfile)}
          </div>
        </div>

        <div className="divider"></div>

        <div className="form__group" >
          <div className="form__field">
            {renderInput('password', editProfile)}
          </div>
        </div>
        <div className="form__group">
          <div className="form__field">
            {renderInput('repeatPassword', editProfile)}
          </div>
        </div>

        {editProfile ? <div className={styles.save + ' form__group form__group--submit'}>
          <LargeButton type="submit" disabled={updating} loading={updating}>
            <span>{translation.translate('profile.save')}</span>
          </LargeButton>
        </div> : null}
      </form>
      {formErrorMessage ?
        <div className="form__error-message" dangerouslySetInnerHTML={{ __html: formErrorMessage }}></div> : null}
    </div>
  );
}