import React, { useState } from 'react';
import SearchIcon from '@/assets/icons/search.inline.svg';
import * as styles from './search.module.scss';

export default function Search({ placeholder, onSearch = null, searchParam = null }) {
  const [searchText, setSearchText] = useState(searchParam);

  const onChange = (e) => {
    onSearch(e.target.value.toLowerCase());
    setSearchText(e.target.value);
  };

  return (
    <form className={styles.searchForm}>
      <SearchIcon className={styles.searchIcon} />
      <input
        className={styles.searchField}
        type="text"
        placeholder={placeholder}
        name="s"
        value={searchText}
        onChange={(e) => onChange(e)}
      />
    </form>
  );
}