import PdfIcon from '@/assets/icons/file-types/pdf.svg';
import VideoIcon from '@/assets/icons/file-types/video.svg';
import TextIcon from '@/assets/icons/file-types/text.svg';
import AudioIcon from '@/assets/icons/file-types/audio.svg';
import SpreadsheetIcon from '@/assets/icons/file-types/spreadsheet.svg';
import DocIcon from '@/assets/icons/file-types/doc.svg';
import DefaultIcon from '@/assets/icons/file-types/default.svg';

export const getPreview = (file: PreviewFile) => {
  if(file.type.startsWith('image/')) {
    return file.preview;
  }

  return getFileTypeIcon(file.type);
};

export const getFileTypeIcon = (contentType: string) => {
  if(contentType === 'application/pdf') {
    return PdfIcon;
  } else if(contentType.startsWith('video/')) {
    return VideoIcon;
  } else if(contentType.startsWith('text/')) {
    return TextIcon;
  } else if(contentType.startsWith('audio/')) {
    return AudioIcon;
  } else if([
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.spreadsheet',
  ].includes(contentType)) {
    return SpreadsheetIcon;
  } else if([
    'application/vnd.oasis.opendocument.text',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/x-abiword'
  ].includes(contentType)) {
    return DocIcon;
  } else {
    return DefaultIcon;
  }
};

export const getMediaType = (contentType: string) => {
  if(contentType.startsWith('image/')) {
    return 'media_types.image';
  } else if(contentType === 'application/pdf') {
    return 'media_types.pdf';
  } else if(contentType.startsWith('video/')) {
    return 'media_types.video';
  } else if(contentType.startsWith('text/')) {
    return 'media_types.text_file';
  } else if(contentType.startsWith('audio/')) {
    return 'media_types.recording';
  } else if([
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.spreadsheet',
  ].includes(contentType)) {
    return 'media_types.spreadsheet';
  } else if([
    'application/vnd.oasis.opendocument.text',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/x-abiword'
  ].includes(contentType)) {
    return 'media_types.document';
  } else {
    return 'media_types.unknown';
  }
};