import { navigate } from 'gatsby';
import { useEffect } from 'react';

export interface IsDeletionSettings {
  to: string,
  isDeletionRoute: boolean;
}

export default function useAuthGuard(authStatus, navigateTo: string, deletionSettings: IsDeletionSettings) {
  const { isAuthenticated, hasLoadedAuth, idTokenResult } = authStatus;

  useEffect(() => {
    let isMounted = true;

    if(isMounted && hasLoadedAuth) {
      if(isAuthenticated) {
        if(typeof idTokenResult?.deleteAt === 'string') {
          // If user is about to be deleted; navigate to the account will be deleted page
          navigate(deletionSettings.to, { replace: true });
        } else if(deletionSettings.isDeletionRoute) {
          navigate(navigateTo);
        }
      } else {
        // Navigate to the login page
        navigate(navigateTo, { replace: true });
      }
    }

    return () => { isMounted = false; };
  }, [isAuthenticated, hasLoadedAuth]);
}