import { TranslationService } from '@/src/services/translation.service';
import React, { useEffect, useState } from 'react';
import { navigate, PageProps } from 'gatsby';
import { ApiService } from '@/src/services/api.service';
import { Link } from 'gatsby';
import Cancel from '@/assets/icons/cancel.inline.svg';
import SignOut from '@/assets/icons/sign-out.inline.svg';
import Settings from '@/assets/icons/settings.inline.svg';

import Header from '@/src/components/header/header';
import { LargeButton } from '@/src/components/button/button';
import ProfileUpdateForm from './profile-update-form';
import Spinner from '@/src/components/spinner/spinner';
import * as styles from './profile.module.scss';
import { useApi } from '@/src/contexts/api.context';

export default function Profile(pageProps: PageProps) {
  const apiService = useApi();
  const [translation] = useState(new TranslationService(pageProps.pageContext));
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [profile, setProfile] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [detailsForm, setDetailsForm] = useState(null);
  const [editProfile, setEditProfile] = useState(false);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      setLoadingProfile(true);
      setProfile(await apiService.getProfile());
    } catch(err) {
      setErrorMessage(translation.translate('profile.failed_to_load_profile'));
    } finally {
      setLoadingProfile(false);
    }
  };

  const signOut = async () => {
    try {
      await apiService.logout();
      await navigate(`${translation.linkPrefix}/login`, { replace: true });
    } catch(err) {
      setErrorMessage(translation.translate('authentication.exit_error'));
    }
  };

  const resetForm = (form) => {

  };

  const toggleEdit = (event) => {
    setEditProfile(!editProfile);
  };

  return (
    <>
      <Header unauthenticated={false} pageContext={pageProps.pageContext} specialHeaderContent={translation.translate('profile.header_text')} goBack={editProfile ? toggleEdit : null}></Header>
      <div className="post__wrapper">
        <div className="post__inner">
          {
            loadingProfile ?
              <div ><Spinner dark={true}></Spinner></div>
              :
              errorMessage
                ?
                <div className="form__error-message" dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
                :
                <div className={`app__wrapper ${styles.profile}`}>
                  <div className={styles.edit__wrapper} onClick={toggleEdit}>
                    <div className={styles.edit} onClick={toggleEdit}>
                      <Settings />
                      <Link to={`${translation.appLinkPrefix}/profile`} >{translation.translate('profile.edit')}</Link>
                    </div>
                  </div>
                  <div className={`${styles.profile__circle}`}>
                    <div className={`${styles.profile__circleLetter}`}> {profile.username.substring(0, 1).toUpperCase()}</div>
                  </div>

                  <ProfileUpdateForm detailsForm={detailsForm} {...pageProps} profile={profile} setProfile={setProfile} editProfile={editProfile} setEditProfile={setEditProfile} updatedProfile={setProfile}></ProfileUpdateForm>

                  <div className={styles.signout + ' form__group form__group--submit'}>
                    <LargeButton type="button" onClick={signOut} >
                      <span>{translation.translate('profile.sign_out')}</span><SignOut />
                    </LargeButton>
                  </div>

                  <div className={styles.delete_wrapper}>
                    <Link className={styles.delete_link} to={`${translation.appLinkPrefix}/delete-account`} >
                      <Cancel ></Cancel> {translation.translate('profile.delete_account')}
                    </Link>
                  </div>
                </div>
          }
        </div>
      </div>

      <div className="decoration-circle-wrapper decoration-circle-wrapper--authenticated">
        <div className="decoration-circle">
        </div>
      </div>
    </>
  );
};