import React, { useEffect, useState } from 'react';
import { Post } from '@/src/models/post.model';
import { PageProps } from 'gatsby';
import * as styles from './post-list.module.scss';
import PostListItem from '../post-list-item/post-list-item';
import { TranslationService } from '@/src/services/translation.service';

type PostListProps = {
  pageContext: PageProps['pageContext'];
  posts?: Post[];
  date?: string;
};

export default function PostList({ pageContext, posts, date }: PostListProps) {
  const [translation] = useState(new TranslationService(pageContext));
  const [activeAccordions, setActiveAccordions] = useState([]);

  const toggleAccordion = (postId) => {
    if(!activeAccordions.includes(postId)) {
      activeAccordions.push(postId);
    } else {
      activeAccordions.splice(activeAccordions.indexOf(postId), 1);
    }

    setActiveAccordions(activeAccordions => ([
      ...activeAccordions
    ]));
  };

  const getExerpt = (str: string) => {
    const firstFiveWords = str.split(/\s+/).slice(0, 5).join(' ');

    if(firstFiveWords.length >= str.length) {
      return str;
    }

    return firstFiveWords + '...';
  };

  useEffect(() => {
    if(posts && posts.length > 0 && posts[0]?.id) {
      setActiveAccordions([posts[0].id]);
    }
  }, []);

  return (
    <>
      {posts && posts.length > 0 ? <div className={styles.postListWrapper}>
        <h1 className={styles.postList__heading}>{date}</h1>
        {
          posts.map((post, index) =>
            <div key={post.id + index} className={styles.postList__accordion + ' ' + (activeAccordions.includes(post.id) ? styles.active : '')}>
              <button className={styles.postList__accordionToggle + ' btn--clean'} onClick={() => { toggleAccordion(post.id); }}>{getExerpt(post.text)}</button>
              <div className={styles.postList__accordionContent}>
                <PostListItem pageContext={pageContext} post={post} />
                {post.media && post.media.length > 0 ?
                  post.media.map((media, index) => <PostListItem key={'postListItem' + index} pageContext={pageContext} media={media} post={post} />) : null}
              </div>
            </div>
          )
        }
      </div> : <div className={styles.noPostsWrapper}>{translation.translate('post_list.no_items')}</div>}

      <div className="decoration-circle-wrapper decoration-circle-wrapper--authenticated">
        <div className="decoration-circle">
        </div>
      </div>
    </>
  );
}