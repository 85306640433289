import React from 'react';
import TrashIcon from '@/assets/icons/trash.inline.svg';
import { CircleButton } from '../button/button';
import { getPreview } from '@/src/helpers/file-types';
import * as styles from './file-list.module.scss';

type FileListProps = {
  files: PreviewFile[];
  removeFile?: (file: PreviewFile, event: React.MouseEventHandler<HTMLButtonElement>) => unknown;
};

export default function FileList({ files, removeFile }: FileListProps) {
  return (
    <div className={styles.filesWrapper}>
      {
        files.map((file, index) => (
          <div key={file.name + index}>
            <div>
              {
                <div className={styles.previewRow}>
                  <img
                    className={styles.previewImg}
                    src={getPreview(file)}
                    alt={file.name}
                  />
                  <span>{file.name}</span>
                  <div className={styles.buttonWrapper}>
                    <CircleButton
                      className={styles.removeButton}
                      type="button">
                      <TrashIcon className={styles.removeSvg}
                        onClick={removeFile ? (event: React.MouseEventHandler<HTMLButtonElement>) => { removeFile(file, event); } : null}>
                      </TrashIcon>
                    </CircleButton>
                  </div>
                </div>
              }
            </div>
          </div>
        ))
      }
    </div>
  );
}