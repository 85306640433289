import { TranslationService } from '@/src/services/translation.service';
import React, { useState } from 'react';

import Header from '@/src/components/header/header';
import { LargeButton } from '@/src/components/button/button';
import * as styles from './account-will-be-deleted.module.scss';
import format from 'date-fns/format';
import { navigate } from 'gatsby';
import { useApi } from '@/src/contexts/api.context';

export default function AccountWillBeDeleted({ pageContext, deleteAt }) {
  const [translation] = useState(new TranslationService(pageContext));
  const [reActivating, setIsReactivating] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const apiService = useApi();

  const reactivate = async () => {
    setIsReactivating(true);
    setErrorMessage(null);

    try {
      await apiService.reactivateUser();
      navigate(translation.appLinkPrefix, { replace: true });
    } catch(err) {
      setErrorMessage(translation.translate('error_contact_us'));
      setIsReactivating(false);
    }
  };

  return (
    <>
      <Header unauthenticated={false} pageContext={pageContext}></Header>

      <div className={styles.accountWillBeDeletedWrapper}>
        {
          errorMessage ? <div className="form__error-message" dangerouslySetInnerHTML={{ __html: errorMessage }}></div> : null
        }

        <p className={styles.accountWillBeDeletedText}>
          {translation.translate('delete_account.account_will_be_deleted').replace('${date}', format(new Date(deleteAt), 'dd.MM.yyyy'))}
        </p>

        <LargeButton type="button" loading={reActivating} onClick={reactivate}>
          <div className="content">
            <span>{translation.translate('delete_account.reactivate')}</span>
          </div>
        </LargeButton>
      </div>

      <div className="decoration-circle-wrapper decoration-circle-wrapper--authenticated">
        <div className="decoration-circle">
        </div>
      </div>
    </>
  );
};