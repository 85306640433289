import { TranslationService } from '@/src/services/translation.service';
import React, { useMemo } from 'react';
import { useState } from 'react';
import FileList from '@/src/components/file-list/file-list';
import { useDropzone } from 'react-dropzone';
import * as styles from './post-content.module.scss';

const supportedFiles = [
  'image/*',
  'audio/*',
  'video/*',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.text',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/x-abiword',
  'text/*'
];

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column' as 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: 'rgba(52, 59, 60, 0.3)',
  borderStyle: 'dashed',
  backgroundColor: '#F2F9F8',
  color: '#212355',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

export default function PostContent({ pageContext, note, noteChanged, files, addedFile, removeFile }) {
  const [translation] = useState(new TranslationService(pageContext));

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({ accept: supportedFiles, onDropAccepted: addedFile });
  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  return (
    <>
      <textarea
        id="textField"
        className={styles.textField}
        name="textField"
        placeholder={translation.translate('make_a_note_description')}
        value={note ? note : ''}
        onChange={(event) => noteChanged(event.target.value)}></textarea>

      <div className={styles.mediaWrapper}>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p className={styles.addMediaDescription}>{translation.translate('add_media')}</p>
        </div>

        {
          files.length > 0 ? <FileList files={files} removeFile={removeFile} /> : ''
        }
      </div>
    </>
  );
}