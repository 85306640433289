import { useStaticQuery, graphql } from "gatsby";
export const getFaqData = () => {
  const data = useStaticQuery(graphql`
    query loadFaqQueryInner {
      allMongodbEviFaqs(sort: { fields: order, order: ASC }) {
        edges {
          node {
            id
            answer {
              sv
              fi
              en
            }
            question {
              sv
              fi
              en
            }
            order
          }
        }
      }
    }
  `);
  return data;
};
