import useAuthGuard from '@/src/hooks/auth-guard.hook';
import useAuthStatus from '@/src/hooks/auth-status.hook';
import { TranslationService } from '@/src/services/translation.service';
import React, { useEffect, useState } from 'react';
import Spinner from '../spinner/spinner';

type PrivateRouteProps = {
  component: any;
  translation: TranslationService;
  componentProps?: any;
  path?: string;
  default?: boolean;
};

export default function PrivateRoute({ component, translation, componentProps = {}, ...rest }: PrivateRouteProps) {
  const authStatus = useAuthStatus();
  const { hasLoadedAuth, isAuthenticated, idTokenResult } = authStatus;
  const deleteAt = idTokenResult?.deleteAt;

  // If we're navigating to the account will be deleted page we don't have to use the auth guard
  useAuthGuard(
    authStatus,
    `${translation.linkPrefix}/login`,
    {
      to: translation.appLinkPrefix + '/account-will-be-deleted',
      isDeletionRoute: componentProps.isAccountWillBeDeletedRoute,
    }
  );

  const Component = component;
  const [privateRouteReady, setPrivateRouteReady] = useState(false);

  useEffect(() => {
    let isMounted = true;

    if(hasLoadedAuth) {
      if(isAuthenticated) {
        if(isMounted && (!deleteAt || componentProps.isAccountWillBeDeletedRoute)) {
          setPrivateRouteReady(true);
        }
      }
    }

    return () => { isMounted = false; };
  }, [hasLoadedAuth]);

  return privateRouteReady ? <Component deleteAt={deleteAt ? deleteAt : null} {...componentProps} {...rest} /> : <Spinner dark={true} />;
}