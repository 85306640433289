import { TranslationService } from "@/src/services/translation.service";
import React, { useState } from "react";
import Search from "../search/search";
import QuestionAndAnswerBox from "./faq-box";
import * as styles from "./faq-inner-content.module.scss";
import { getFaqData } from "./get-faq-data";
import Cancel from "@/assets/icons/cancel.inline.svg";

type FaqInnerContentProps = {
  pageContext?: any;
  modal?: boolean;
  toggleModal?: any;
  resetHTMLTag?: any;
};

export default function FaqInnerContent({
  pageContext,
  modal,
  toggleModal,
  resetHTMLTag,
}: FaqInnerContentProps) {
  const [translation] = useState(new TranslationService(pageContext));
  const searchParam =
    (typeof location !== "undefined" &&
      new URLSearchParams(location?.search).get("s")?.toLowerCase()) ||
    "";

  const data = getFaqData();

  const onSearch = (val: string) => {
    updateFaqStates(
      data.allMongodbEviFaqs.edges.filter((edge) => {
        if (
          edge.node.question[translation.lang].toLowerCase().includes(val) ||
          edge.node.answer[translation.lang].toLowerCase().includes(val)
        ) {
          return edge;
        }
      })
    );

    if (history.pushState) {
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?s=" +
        val;
      window.history.pushState({ path: newurl }, "", newurl);
    }
  };

  const [faqStates, updateFaqStates] = useState(
    data.allMongodbEviFaqs.edges.filter((edge) => {
      if (
        !searchParam ||
        edge.node.question[translation.lang].toLowerCase().includes(searchParam)
      ) {
        return edge;
      }
    })
  );

  const closeModal = (event) => {
    if (event.target.classList.contains("closeOnClickClass")) {
      toggleModal(!modal);
      resetHTMLTag();
    }
  };

  return (
    <>
      {modal ? (
        <div
          className={styles.faqModalBackdrop + " closeOnClickClass"}
          onClick={closeModal}
        >
          <div className={styles.faqModalWrapper}>
            <div
              className={styles.closeModalWrapper + " closeOnClickClass"}
              onClick={closeModal}
            >
              <div
                className={
                  styles.closeModalButton +
                  " " +
                  styles.content +
                  " closeOnClickClass"
                }
                onClick={closeModal}
              >
                <Cancel className={" closeOnClickClass"} onClick={closeModal} />
              </div>
            </div>
            <div>
              <h1 className={styles.faq__heading}>
                {translation.translate("faq.any_questions")}
              </h1>
              <p className={styles.faq__subHeading}>
                {translation.translate("faq.info")}
              </p>

              <Search
                placeholder={translation.translate(
                  "faq.search_bar_placeholder"
                )}
                onSearch={onSearch}
                searchParam={searchParam}
              />
              <h2 className={styles.faq__heading}>
                {translation.translate("faq.common_questions")}
              </h2>

              <div className={styles.faq__wrapper}>
                {faqStates.length > 0 ? (
                  <>
                    {faqStates.map((edge) => (
                      <QuestionAndAnswerBox
                        key={edge.node.id}
                        question={edge.node.question[translation.lang]}
                        answer={edge.node.answer[translation.lang]}
                      ></QuestionAndAnswerBox>
                    ))}
                  </>
                ) : (
                  <p className={styles.faq__notFound}>
                    {translation.translate("faq.not_found")}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h1 className={styles.faq__heading}>
            {translation.translate("faq.any_questions")}
          </h1>
          <p className={styles.faq__subHeading}>
            {translation.translate("faq.info")}
          </p>

          <Search
            placeholder={translation.translate("faq.search_bar_placeholder")}
            onSearch={onSearch}
            searchParam={searchParam}
          />
          <h2 className={styles.faq__heading}>
            {translation.translate("faq.common_questions")}
          </h2>

          <div className={styles.faq__wrapper}>
            {faqStates.length > 0 ? (
              <>
                {faqStates.map((edge) => (
                  <QuestionAndAnswerBox
                    key={edge.node.id}
                    question={edge.node.question[translation.lang]}
                    answer={edge.node.answer[translation.lang]}
                  ></QuestionAndAnswerBox>
                ))}
              </>
            ) : (
              <p className={styles.faq__notFound}>
                {translation.translate("faq.not_found")}
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
}
