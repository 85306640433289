import { TranslationProvider } from '@/src/contexts/translation.context';
import { TranslationService } from '@/src/services/translation.service';
import React, { useEffect, useState } from 'react';
import { navigate, PageProps } from 'gatsby';
import { ApiService } from '@/src/services/api.service';

import DeleteAccountConfirmation from './delete-account-confirmation';
import DeleteAccountForm from './delete-account-form';
import Header from '@/src/components/header/header';
import Spinner from '@/src/components/spinner/spinner';
import { useApi } from '@/src/contexts/api.context';

export default function DeleteAccount(pageProps: PageProps) {
  const apiService = useApi();
  const [translation] = useState(new TranslationService(pageProps.pageContext));
  const [securityQuestions, setSecurityQuestions] = useState(null);
  const [securityQuestionsForm, setSecurityQuestionsForm] = useState(null);
  const [loadingSecurityQuestions, setLoadingSecurityQuestions] = useState(null);
  const [checkingSecurityAnswers, setCheckingSecurityAnswers] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isNextStep, setIsNextStep] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      setLoadingSecurityQuestions(true);

      const result = await apiService.getSecurityQuestions();
      setSecurityQuestions(result.securityQuestions);

    } catch(err) {
      setErrorMessage(translation.translate('authentication.invalid_argument'));
    } finally {
      setLoadingSecurityQuestions(false);
    }
  };

  const checkAnswers = async (form) => {
    setErrorMessage(null);
    setCheckingSecurityAnswers(true);
    try {
      setSecurityQuestionsForm(form);
      await apiService.checkAnswers({
        securityQuestions: securityQuestions.map((question, i) => {
          return {
            ...question,
            answer: form['securityAnswer' + i].value
          };
        })
      });
    } catch(err) {
      setErrorMessage(translation.translate('delete_account.securityQuestionsError'));
    } finally {
      setCheckingSecurityAnswers(false);
      setIsNextStep(true);
    }
  };

  const deleteAccount = async (form) => {
    setErrorMessage(null);
    setSubmitting(true);

    try {
      await apiService.deleteUserRequest({
        securityQuestions: securityQuestions.map((question, i) => {
          return {
            ...question,
            answer: securityQuestionsForm['securityAnswer' + i].value
          };
        }),
        comment: form['textField'].value
      });

      setFinished(true);
      await navigate(translation.appLinkPrefix + '/account-will-be-deleted', { replace: true });
    } catch(err) {
      setErrorMessage(translation.translate('forgot_password.submit_new_password_error'));
      setSubmitting(false);
    }
  };

  // TODO: toggle errormessage on back
  const back = () => {
    setSecurityQuestionsForm(securityQuestionsForm);
    setIsNextStep(false);
  };

  return (
    <TranslationProvider value={translation}>
      <Header
        unauthenticated={false}
        pageContext={pageProps.pageContext}
        specialHeaderContent={translation.translate('profile.delete_account')}
        goBack={() => {
          history.back();
        }}></Header>
      <div className="post__wrapper">
        <div className="post__inner">
          {
            loadingSecurityQuestions ?
              <div><Spinner dark={true}></Spinner></div>
              :
              errorMessage
                ?
                <div className={'form__error-message'} dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
                :
                securityQuestions && !isNextStep ?
                  <DeleteAccountForm
                    pageContext={pageProps.pageContext}
                    securityQuestions={securityQuestions}
                    securityQuestionsForm={securityQuestionsForm}
                    checkAnswers={checkAnswers}
                    validating={checkingSecurityAnswers}
                    error={errorMessage} ></DeleteAccountForm> :
                  !finished ?
                    <DeleteAccountConfirmation
                      pageContext={pageProps.pageContext}
                      submitting={submitting}
                      error={errorMessage}
                      deleteAccount={deleteAccount} ></DeleteAccountConfirmation> : <Spinner dark={true}></Spinner>
          }
        </div>
      </div>

      <div className="decoration-circle-wrapper decoration-circle-wrapper--authenticated">
        <div className="decoration-circle">
        </div>
      </div>
    </TranslationProvider >
  );
}