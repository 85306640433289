import { TranslationService } from "@/src/services/translation.service";
import React, { useEffect, useState } from "react";
import { PageProps } from "gatsby";
import {
  checkboxRequiredValidator,
  createFormField,
  emailValidator,
  requiredValidator,
} from "@/src/models/form.model";
import { useForm } from "@/src/hooks/form.hook";

import { FormError } from "@/src/components/form-error/form-error";
import Header from "@/src/components/header/header";
import { LargeButton } from "@/src/components/button/button";
import * as styles from "./send-report.module.scss";
import { useApi } from "@/src/contexts/api.context";

const emailForm = {
  email: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return (
          <>
            <div className="form__group">
              <input
                key={key}
                id="email"
                name="email"
                placeholder={translation.translate("authentication.email")}
                type="text"
                autoComplete="email"
                value={value ? value : ""}
                onChange={onInputChange}
                disabled={false}
              />
            </div>
            <FormError valid={isValid} message={error}></FormError>
          </>
        );
      }
    ),
    validationRules: [requiredValidator(), emailValidator()],
  },
  extra: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return (
          <>
            <div className="form__group">
              <textarea
                key={key}
                id="extra"
                name="extra"
                placeholder={translation.translate(
                  "send_report.extra_placeholder"
                )}
                autoComplete="off"
                value={value ? value : ""}
                onChange={onInputChange}
                disabled={false}
              ></textarea>
            </div>
          </>
        );
      }
    ),
  },
  CheckListbox2: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return (
          <>
            <input
              key={key}
              id="CheckListbox2"
              name="CheckListbox2"
              type="checkbox"
              checked={value ? true : false}
              onChange={onInputChange}
            />
            <label className="checkbox-label">
              <span>
                {translation.translate("send_report.check_list.item2")}
              </span>
              <span className={styles.star}> *</span>
            </label>
            <FormError valid={isValid} message={error}></FormError>
          </>
        );
      }
    ),
    validationRules: [checkboxRequiredValidator()],
  },
  CheckListbox3: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return (
          <>
            <input
              key={key}
              id="CheckListbox3"
              name="CheckListbox3"
              type="checkbox"
              checked={value ? true : false}
              onChange={onInputChange}
            />
            <label className="checkbox-label">
              <span>
                {translation.translate("send_report.check_list.item3")}
              </span>
              <span className={styles.star}> *</span>
            </label>
            <FormError valid={isValid} message={error}></FormError>
          </>
        );
      }
    ),
    validationRules: [checkboxRequiredValidator()],
  },
  confirmationCheckbox: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return (
          <>
            <input
              key={key}
              id="confirmationCheckbox"
              name="confirmationCheckbox"
              type="checkbox"
              checked={value ? true : false}
              onChange={onInputChange}
            />
            <label className="checkbox-label">
              <span>{translation.translate("send_report.confirmation")}</span>
              <span className={styles.star}> *</span>
            </label>
            <FormError valid={isValid} message={error}></FormError>
          </>
        );
      }
    ),
    validationRules: [checkboxRequiredValidator()],
  },
};

export default function SendReport(pageProps: PageProps) {
  const apiService = useApi();
  const [translation] = useState(new TranslationService(pageProps.pageContext));
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const { renderInput, isFormValid, touchForm, form } = useForm(
    translation,
    emailForm
  );

  const submit = (event: React.FormEvent) => {
    event.preventDefault();
    touchForm();
    setSubmitAttempted(true);
    return false;
  };

  useEffect(() => {
    if (submitAttempted) {
      if (isFormValid()) {
        sendReport();
      }
      setSubmitAttempted(false);
    }
  }, [submitAttempted]);

  const sendReport = async () => {
    try {
      setErrorMessage(null);
      setSuccessMessage(null);
      setLoading(true);
      setDisabled(false);

      await apiService.generateReport({
        contactEmail: form.email.value,
        extra: form.extra.value,
      });

      setDisabled(true);
      setSuccessMessage(translation.translate("send_report.success"));
    } catch (err) {
      setDisabled(false);
      setErrorMessage(
        translation
          .translate("error_contact_us")
          .replace(
            "{supportEmail}",
            '<a href="mailto:support@evidencediary.com">support@evidencediary.com</a>'
          )
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header
        unauthenticated={false}
        pageContext={pageProps.pageContext}
        specialHeaderContent={translation.translate("send_report.header_text")}
      ></Header>
      <div className="post__wrapper">
        <div className="post__inner">
          <form className="form" onSubmit={submit}>
            <h1>
              {" "}
              {translation.translate("send_report.send_to_contact_person")}
            </h1>
            <div className={styles.report__description}>
              {" "}
              {translation.translate("send_report.contact_info")}
            </div>
            <h2>
              {translation.translate("send_report.check_list.check_list")}
            </h2>
            <div
              className={
                styles.report__description +
                " " +
                styles.report__descriptionLast
              }
            >
              {translation.translate("send_report.check_list.info")}
            </div>
            <div className="report__checkbox">
              {renderInput("CheckListbox2")}
            </div>
            <div className="report__checkbox">
              {renderInput("CheckListbox3")}
            </div>

            <div>
              <h4 className={styles.report__formHeading}>
                {translation.translate("send_report.contact_email")}
                <span className={styles.star}> *</span>
              </h4>
              {renderInput("email")}
              <div className={styles.report__formDescription}>
                {translation.translate("send_report.send_to_confirmation_info")}
              </div>
            </div>

            <div>
              <h4 className={styles.report__formHeading}>
                {translation.translate("send_report.extra")}
              </h4>
              {renderInput("extra")}
              <div className={styles.report__formDescription}>
                {translation.translate("send_report.extra_info")}
              </div>
            </div>

            <div className="report__checkbox">
              {renderInput("confirmationCheckbox")}
            </div>

            {errorMessage && (
              <div
                className="form__error-message"
                dangerouslySetInnerHTML={{ __html: errorMessage }}
              ></div>
            )}

            {successMessage && (
              <div
                className="form__success-message"
                dangerouslySetInnerHTML={{ __html: successMessage }}
              ></div>
            )}

            <div className="form__group form__group--submit">
              <LargeButton type="submit" loading={loading} disabled={disabled}>
                <span>{translation.translate("send_report.header_text")}</span>
              </LargeButton>
            </div>
          </form>
        </div>
      </div>

      <div className="decoration-circle-wrapper decoration-circle-wrapper--authenticated">
        <div className="decoration-circle"></div>
      </div>
    </>
  );
}
