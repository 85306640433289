import React, { useEffect, useState } from 'react';
import { createFormField, requiredValidator } from '@/src/models/form.model';
import { TranslationService } from '../../../services/translation.service';
import { useForm } from '@/src/hooks/form.hook';

import { FormError } from '@/src/components/form-error/form-error';
import { LargeButton } from '@/src/components/button/button';
import Spinner from '@/src/components/spinner/spinner';
import * as styles from './delete-account.module.scss';

export default function DeleteAccountForm({ pageContext, securityQuestionsForm, securityQuestions, checkAnswers, validating, error }) {
  const defaultSecurityQuestionsForm = {
  };

  for(let i = 0; i < securityQuestions.length; i++) {
    defaultSecurityQuestionsForm['securityAnswer' + i] = {
      ...createFormField(
        (onInputChange, translation, value, isValid, error, key) => {
          return <>
            <input
              key={key}
              id={'securityAnswer' + i}
              name={'securityAnswer' + i}
              placeholder={translation.translate('forgot_password.security_answer_placeholder')}
              type="text"
              value={value ? value : ''}
              onChange={onInputChange} />
            <FormError valid={isValid} message={error}></FormError>
          </>;
        }
      ),
      validationRules: [
        requiredValidator()
      ]
    };
  }

  securityQuestionsForm = securityQuestionsForm || defaultSecurityQuestionsForm;
  const [translation] = useState(new TranslationService(pageContext));
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const { renderInput, isFormValid, touchForm, form } = useForm(translation, securityQuestionsForm);

  const submit = (event: React.FormEvent) => {
    event.preventDefault();
    touchForm();
    setSubmitAttempted(true);
    return false;
  };

  useEffect(() => {
    if(submitAttempted) {
      if(isFormValid()) {
        checkAnswers(form);
      }
      setSubmitAttempted(false);
    }
  }, [submitAttempted]);

  return (
    <form className="form" onSubmit={submit} autoComplete="off">

      <h1>{translation.translate('delete_account.verify_identity')}</h1>

      <p className="auth__description">{translation.translate('delete_account.veriy_identity_info')}</p>

      {
        securityQuestions.map((question, i) => {
          return <div className={styles.questionGroup} key={i}>
            <p className={styles.questionHeading}>{question.question}</p>

            <div className="form__group">
              <div className="form__field">
                {renderInput('securityAnswer' + i)}
              </div>
            </div>
          </div>;
        })
      }

      {error ?
        <div className={styles.deleteError + ' form__error-message'} dangerouslySetInnerHTML={{ __html: error }}></div> : null}

      <div className="form__group form__group--submit">
        <LargeButton type="submit" loading={validating}>
          <span>{translation.translate('delete_account.proceed')}</span>
        </LargeButton>
      </div>

    </form>
  );
}