import React, { useEffect, useState } from 'react';
import { TranslationService } from '@/src/services/translation.service';
import format from 'date-fns/format';
import { SmallButton } from '@/src/components/button/button';
import Plus from '@/assets/icons/plus.inline.svg';
import { navigate } from 'gatsby-link';
import PostTypeList from '../post-type-list/post-type-list';
import * as styles from './calendar-posts.module.scss';

type CalendarModalProps = {
  landing?: boolean;
  unauthenticated?: boolean;
  pageContext?: any;
  data: any;
  close: any;
  setHeaderProps: Function;
};

export default function CalendarPostsModal({ pageContext, data }: CalendarModalProps) {
  const [translation] = useState(new TranslationService(pageContext));
  const [loading, setLoading] = useState(false);

  const openPost = () => {
    setLoading(true);
    navigate(`${translation.appLinkPrefix}/post${data.date ? `?date=${format(data.date, 'yyyy-MM-dd')}` : ''}`);
  };

  const openPostsList = () => {
    setLoading(true);
    navigate(`${translation.appLinkPrefix}/posts/${format(data.date, 'yyyy-MM-dd')}`);
  };

  return (
    <div className={'modal ' + (data ? 'modal--active' : '')}>
      {data &&
        <>
          <div className={styles.modalInner} >
            <div className={styles.notesWrapper} >
              <button className={styles.modal__notesBtn + ' btn--clean'} onClick={openPostsList} >
                <h2 className={styles.h2}>{
                  translation.translate('calendar.dayShort.' + format(data.date, 'EEEEEE')) + ' ' +
                  format(data.date, 'd') + ' ' +
                  translation.translate('calendar.month.' + format(data.date, 'LLLL'))
                }</h2>
                {data.posts?.length > 0 ? `${data.posts.length} ${translation.translate('posts')}` : translation.translate('no_posts')}
              </button>
            </div>
            <div className={styles.modal__btnWrapper}>
              <SmallButton type="button" loading={loading} onClick={openPost}>
                <div className="content">
                  <span>{translation.translate('add')}</span>
                  <Plus></Plus>
                </div>
              </SmallButton>
            </div>
          </div>
          <div className={styles.modalNotesWrapper} onClick={openPostsList}>
            <div className={styles.modalNotes}>
              <PostTypeList pageContext={pageContext} posts={data.posts} />
            </div>
          </div>
        </>
      }
    </div>
  );
};