// extracted by mini-css-extract-plugin
export var astext = "post-module--astext--XF0GV";
export var beforeYouSave = "post-module--before-you-save--VI8+x";
export var createNote__wrapper = "post-module--create-note__wrapper--S5BIt";
export var errorMessage = "post-module--error-message--Akrpd";
export var fileSelectorWrapper = "post-module--file-selector-wrapper--YJhMx";
export var h2 = "post-module--h2--WscRv";
export var quickLogHeading = "post-module--quick-log-heading--Gm8TD";
export var quickLogSection = "post-module--quick-log-section--UogtZ";
export var quickLog__wrapper = "post-module--quick-log__wrapper--ZyNMS";
export var saveWrapper = "post-module--save-wrapper--9cGdQ";
export var timePickerSelectWrapper = "post-module--time-picker-select-wrapper--Hw3Ys";
export var timePickerWrapper = "post-module--time-picker-wrapper--Ak-up";
export var uploadedFile = "post-module--uploaded-file--8brna";
export var uploadedFileWrapper = "post-module--uploaded-file-wrapper--OUk+7";