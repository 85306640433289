import React from 'react';
import { Post } from '@/src/models/post.model';
import { PageProps } from 'gatsby';
import PostTypeListItem from '../post-type-list-item/post-type-list-item';
import * as styles from './post-type-list.module.scss';

type PostTypeListProps = {
  pageContext: PageProps['pageContext'];
  posts?: Post[];
};

export default function PostTypeList({ pageContext, posts }: PostTypeListProps) {
  return (
    <>
      {posts && posts.length > 0 ? posts.map((post, index) =>
        <div key={post.id + index} className={styles.postTypeList}>
          <PostTypeListItem pageContext={pageContext} post={post} />
          {post.media && post.media.length > 0 ?
            post.media.map((media, index) => <PostTypeListItem key={'postTypeListItem' + index} pageContext={pageContext} media={media} />) : null}
        </div>
      ) : null}
    </>
  );
}