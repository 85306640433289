import React, { useState } from 'react';
import { Media, Post } from '@/src/models/post.model';
import { PageProps } from 'gatsby';
import { TranslationService } from '@/src/services/translation.service';
import { getMediaType } from '@/src/helpers/file-types';
import * as styles from './post-type-list-item.module.scss';

type PostTypeListProps = {
  pageContext: PageProps['pageContext'];
  post?: Post;
  media?: Media;
};

export default function PostTypeListItem({ pageContext, post, media }: PostTypeListProps) {
  const [translation] = useState(new TranslationService(pageContext));

  return (
    <div className={styles.listItemWrapper}>
      <div className={styles.circle}></div>
      <span>
        {post ? translation.translate('note') : translation.translate(getMediaType(media.contentType))}
      </span>
    </div>
  );
}