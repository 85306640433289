import React, { useEffect, useState } from 'react';
import { TranslationService } from '@/src/services/translation.service';
import { LargeButton, TextButton } from '@/src/components/button/button';
import File from '@/assets/icons/file.inline.svg';
import * as styles from './confirmation-modal.module.scss';

type ConfirmationModalProps = {
  unauthenticated?: boolean;
  pageContext?: any;
  confirmationPopupActive: boolean;
  setConfirmationPopup: any;
  saveLoading: any;
  save: any;
  resetHTMLTag: any;
};

export default function ConfirmationModal({ pageContext, confirmationPopupActive, setConfirmationPopup, saveLoading, save, resetHTMLTag }: ConfirmationModalProps) {
  const [translation] = useState(new TranslationService(pageContext));

  const saveConfirmed = () => {
    save();
    setConfirmationPopup(!confirmationPopupActive);
    resetHTMLTag();
  };

  const closeModal = (event) => {
    //event.stopPropagation();
    setConfirmationPopup(false);
    resetHTMLTag();
  };

  return (
    <div className={styles.confirmatModalBackdrop}>
      <div className={styles.confirmationModalWrapper}>
        <div className={styles.fileIconWrapper}><File className={styles.icon}></File></div>
        <div>
          <h2 className={styles.h2}>{translation.translate('confirm_note.header')}</h2>
          <div className={styles.descriptionText}>{translation.translate('confirm_note.description')}</div>
        </div>
        <div>
          <LargeButton type="button" loading={saveLoading} onClick={saveConfirmed}>
            <div className="content">
              <span>{translation.translate('confirm_note.save')}</span>
            </div>
          </LargeButton>
        </div>
        <TextButton className={styles.decline} onClick={closeModal} type={'button'}>
          <div>
            <span>{translation.translate('confirm_note.decline')}</span>
          </div>
        </TextButton>
      </div>
    </div>
  );
};