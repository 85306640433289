import React, { useState } from 'react';
import * as styles from './faq-box.module.scss';

export default function QuestionAndAnswerBox({ question, answer }) {
  const [questionToggled, setQuestionToggled] = useState(false);
  const toggleQuestion = (event) => {
    setQuestionToggled(!questionToggled);
  };

  return (
    <div className={styles.faq__question + ' ' + (questionToggled ? styles.active : '')}>
      <button className={styles.faq__toggle + ' btn--clean'} onClick={toggleQuestion}>{question}</button>
      <div className={styles.faq__answer}> <div className={styles.faq__answerText} dangerouslySetInnerHTML={{ __html: answer }}></div> </div>
    </div >
  );
}

