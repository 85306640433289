import React, { useState } from 'react';
import { TranslationService } from '@/src/services/translation.service';
import * as styles from './quick-log.module.scss';

export default function QuickLog({ pageContext, item, onClick }) {
  const [translation] = useState(new TranslationService(pageContext));
  const Icon = item.icon;

  return (
    <div className={
      styles.quickLog__iconBox + ' ' +
      (item.selected ?
        (' ' + styles.iconBoxSelected) : '')} onClick={((e) => onClick(e, item))}>
      <div className={styles.iconWrapper}>
        <Icon className={styles.icon + ' ' +
          (item.selected ?
            (' ' + styles.iconSelected) : '')} />
      </div>
      <span className={styles.quickLogLabel}>{translation.translate(item.key)}</span>
    </div>
  );
}