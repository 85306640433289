import { TranslationService } from '@/src/services/translation.service';
import React, { useEffect, useState } from 'react';
import { Link, navigate, PageProps } from 'gatsby';
import Control from '@/assets/icons/quick-log/control.inline.svg';
import Financial from '@/assets/icons/quick-log/financial.inline.svg';
import Isolation from '@/assets/icons/quick-log/isolation.inline.svg';
import Physical from '@/assets/icons/quick-log/physical.inline.svg';
import Psychological from '@/assets/icons/quick-log/psychological.inline.svg';
import Sexual from '@/assets/icons/quick-log/sexual.inline.svg';
import Threat from '@/assets/icons/quick-log/threat.inline.svg';
import Verbal from '@/assets/icons/quick-log/verbal.inline.svg';
import format from 'date-fns/format';
import { parse, setHours, setMinutes } from 'date-fns';
import { DropEvent } from 'react-dropzone';
import { QuickIcon } from '@/src/models/quick-icon.model';

import Header from '@/src/components/header/header';
import PostContent from '@/src/components/post-content/post-content';
import QuickLog from '@/src/components/quick-log/quick-log';
import * as styles from './post.module.scss';
import ConfirmationModal from '@/src/components/calendar-posts/confirmation-modal';
import { LargeButton } from '@/src/components/button/button';
import FaqInnerContent from '@/src/components/faq-box/faq-inner-content';
import { useApi } from '@/src/contexts/api.context';
import { ObjectID } from 'bson';

const defaultLocalFiles: PreviewFile[] = [];
const defaultQuickIcons: QuickIcon[] = [
  {
    key: 'control',
    icon: Control,
    selected: false
  },
  {
    key: 'financial',
    icon: Financial,
    selected: false
  },
  {
    key: 'isolation',
    icon: Isolation,
    selected: false
  },
  {
    key: 'physical',
    icon: Physical,
    selected: false
  },
  {
    key: 'psychological',
    icon: Psychological,
    selected: false
  },
  {
    key: 'sexual',
    icon: Sexual,
    selected: false
  },
  {
    key: 'threat',
    icon: Threat,
    selected: false
  },
  {
    key: 'verbal',
    icon: Verbal,
    selected: false
  },
];

export default function Post(pageProps: PageProps) {
  const apiService = useApi();
  const [translation] = useState(new TranslationService(pageProps.pageContext));
  const [date] = useState(typeof location !== 'undefined' ? new URLSearchParams(location?.search).get('date') : null);
  const [note, setNote] = useState('');
  const [postHours, setPostHours] = useState('');
  const [postMinutes, setPostMinutes] = useState('');
  const [localFiles, setLocalFiles] = useState(defaultLocalFiles);
  const [quickIconList, setQuickIconList] = useState(defaultQuickIcons);
  const [saveLoading, setSaveLoading] = useState(false);
  const [confirmationPopupActive, setConfirmationPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [FaqModal, toggleFaqModal] = useState(false);
  const [startUrl] = useState(window.location.href);

  useEffect(() => {
    window.addEventListener('popstate', handleBackEvent);

    // cleanup this component
    return () => {
      window.removeEventListener('popstate', handleBackEvent);
    };

  }, [FaqModal, confirmationPopupActive]);

  const handleBackEvent = (event) => {
    if(FaqModal) {
      toggleFaqModal(false);
    }
    if(confirmationPopupActive) {
      setConfirmationPopup(false);
    }
    //replace state to avoid url change without modal
    //window.history.replaceState({ path: startUrl }, '', startUrl);
  };

  const onModalOpen = (val: string) => {
    if(history.pushState) {
      var newurl = startUrl + 'modal=' + val;
      window.history.pushState({ path: newurl }, '');
    }
  };
  const openFaqModal = (event) => {
    toggleFaqModal(true);
    onModalOpen('faq');
  };
  const openConfirmationModal = (event) => {
    setConfirmationPopup(true);
    onModalOpen('confirmation');
  };

  const resetHTMLTag = () => {
    //event.stopPropagation();
    window.history.pushState({ path: startUrl }, '', startUrl);
  };

  const quickIconClick = (event, item) => {
    const indexOfKey = quickIconList.findIndex(element => element.key == item.key);
    setQuickIconList(quickIconList.map((item, index) => {
      return index === indexOfKey ? {
        key: quickIconList[indexOfKey]['key'],
        icon: quickIconList[indexOfKey]['icon'],
        selected: !quickIconList[indexOfKey]['selected']
      } : item;
    }));
  };

  const getTags = () => {
    return quickIconList.filter(item => item.selected).map(item => item.key);
  };

  const addedFile = (files: File[], event: DropEvent) => {
    setLocalFiles([...localFiles, ...files.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    }))]);
  };

  const removeFile = (file) => {
    setLocalFiles(localFiles.filter(f => f !== file));
  };

  const uploadFiles = async (postId) => {
    return await Promise.all(localFiles.map(file => uploadFile(postId, file)));
  };

  const uploadFile = async (postId: string, file: File) => {
    return await apiService.uploadFile(await apiService.sign('post-media', postId, file.name), file);
  };

  const save = async () => {
    //if note is empty set error message
    if(note) {
      try {
        setErrorMessage(null);
        setSaveLoading(true);
        const _id = new ObjectID();
        const files = localFiles.length > 0 ? await uploadFiles(_id.toString()) : [];

        const postDate = postHours && postMinutes ?
          setMinutes(setHours(parse(date, 'yyyy-MM-dd', new Date()), parseInt(postHours)), parseInt(postMinutes)) :
          parse(date, 'yyyy-MM-dd', new Date());

        await apiService.createPost({
          _id,
          date: postDate,
          timeChosen: postHours && postMinutes ? true : false,
          text: note,
          tags: getTags(),
          media: files.length > 0 ? files.map(upload => {
            return {
              path: upload.signResponse.fields.key,
              contentType: upload.headObject.ContentType,
              originalName: upload.headObject.Metadata.originalname
            };
          }) : []
        });

        await navigate(`${translation.appLinkPrefix}/posts/${date}`, { replace: true });
      } catch(err) {
        setErrorMessage(translation.translate('create_post.error'));
      } finally {
        setSaveLoading(false);
        setConfirmationPopup(false);
      }
    }
    else {
      setErrorMessage(translation.translate('create_post.error'));
    }
  };

  const getPossibleHours = () => {
    const hours: string[] = [];

    for(let i = 0; i < 24; i++) {
      hours.push(i < 10 ? '0' + i : '' + i);
    }

    return hours;
  };

  const getPossibleMinutes = () => {
    const minutes: string[] = [];

    for(let i = 0; i < 60; i++) {
      minutes.push(i < 10 ? '0' + i : '' + i);
    }

    return minutes;
  };

  return (
    <>
      <Header unauthenticated={false}
        pageContext={pageProps.pageContext}
        specialHeaderContent={
          date ?
            translation.translate('calendar.dayLong.' + format(new Date(date), 'EEEE')) + ' ' +
            format(new Date(date), 'dd.MM')
            :
            translation.translate('create_post.create_new_post')}
        goBack={() => {
          history.back();
        }} />
      <div className="post__wrapper">
        <div className="post__inner">
          {
            <>
              <div className={styles.timePickerWrapper}>
                <h2 className={styles.h2}>{translation.translate('select_time')}</h2>

                <div className={styles.timePickerSelectWrapper}>
                  <select value={postHours} onChange={(event) => setPostHours(event.target.value)}>
                    <option value="">HH</option>
                    {getPossibleHours().map(hh => {
                      return <option key={hh} value={hh}>{hh}</option>;
                    })}
                  </select>
                  <span>:</span>
                  <select value={postMinutes} onChange={(event) => setPostMinutes(event.target.value)}>
                    <option value="">MM</option>
                    {getPossibleMinutes().map(mm => {
                      return <option key={mm} value={mm}>{mm}</option>;
                    })}
                  </select>
                </div>
              </div>

              <div className={styles.quickLogSection}>
                <h2 className={styles.h2 + ' ' + styles.quickLogHeading}>{translation.translate('quick_log')}</h2>
                <div>{translation.translate('quick_log_description')} </div>
                <div className={styles.quickLog__wrapper}>
                  {quickIconList.map((item) => <QuickLog key={item.key} pageContext={pageProps.pageContext} item={item} onClick={quickIconClick} />)}
                </div>
              </div>

              <div className={styles.createNote__wrapper}>
                <div>
                  <h1>{translation.translate('descirbe_the_event')}</h1>
                  <span>{translation.translate('descirbe_the_event_description')}</span>

                  <PostContent
                    note={note}
                    noteChanged={setNote}
                    files={localFiles}
                    addedFile={addedFile}
                    removeFile={removeFile}
                    pageContext={pageProps.pageContext}
                  />
                </div>
              </div>
            </>
          }

          {
            errorMessage ? <div className={'form__error-message ' + styles.errorMessage} dangerouslySetInnerHTML={{ __html: errorMessage }}></div> : null
          }
          <div className={styles.beforeYouSave} >
            <span>{translation.translate('before_you_save_start')}</span>
            <button className={styles.astext} onClick={openFaqModal}>{translation.translate('menu.questions_and_answers')} </button>
            <span>{translation.translate('before_you_save_end')}</span>
          </div>
          <div className={styles.saveWrapper}>
            <LargeButton type="button" loading={saveLoading} disabled={note ? false : true} onClick={openConfirmationModal}>
              <span>{translation.translate('save')}</span>
            </LargeButton>
          </div>
          {FaqModal ? <FaqInnerContent
            pageContext={pageProps.pageContext}
            modal={true}
            toggleModal={toggleFaqModal}
            resetHTMLTag={resetHTMLTag} />
            : null}

          {confirmationPopupActive ? <ConfirmationModal
            pageContext={pageProps.pageContext}
            confirmationPopupActive={confirmationPopupActive}
            setConfirmationPopup={setConfirmationPopup}
            saveLoading={saveLoading}
            save={save}
            resetHTMLTag={resetHTMLTag} />
            : null}
        </div>
      </div>

      <div className="decoration-circle-wrapper decoration-circle-wrapper--authenticated">
        <div className="decoration-circle">
        </div>
      </div>

    </>
  );
};