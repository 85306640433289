// extracted by mini-css-extract-plugin
export var backDrop = "calendar-posts-module--back-drop--obAhQ";
export var calendar = "calendar-posts-module--calendar--aA8go";
export var calendar__desktopInner = "calendar-posts-module--calendar__desktop-inner--SAm+Z";
export var calendar__desktopStepper = "calendar-posts-module--calendar__desktop-stepper--Ozf8r";
export var calendar__desktopStepperBtn = "calendar-posts-module--calendar__desktop-stepper-btn--K9Myj";
export var calendar__desktopStepperInner = "calendar-posts-module--calendar__desktop-stepper-inner--1cKfn";
export var calendar__desktopStepperYear = "calendar-posts-module--calendar__desktop-stepper-year--sBoPM";
export var calendar__desktopWrapper = "calendar-posts-module--calendar__desktop-wrapper--Ge4Ts";
export var calendar__mobileWrapper = "calendar-posts-module--calendar__mobile-wrapper--UmAUq";
export var h2 = "calendar-posts-module--h2--4Vx4m";
export var modalInner = "calendar-posts-module--modal-inner--mWSez";
export var modalNotes = "calendar-posts-module--modal-notes--ajwJ1";
export var modalNotesWrapper = "calendar-posts-module--modal-notes-wrapper--c38GF";
export var modal__btnWrapper = "calendar-posts-module--modal__btn-wrapper--zIilm";
export var modal__notesBtn = "calendar-posts-module--modal__notes-btn--r6IN9";
export var notesWrapper = "calendar-posts-module--notesWrapper--hOr5h";
export var todayDot = "calendar-posts-module--today-dot--Nvprn";