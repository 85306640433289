import React, { useEffect, useState } from 'react';
import { TranslationService } from '@/src/services/translation.service';
import InfiniteScroll from 'react-infinite-scroll-component';
import DayPicker from 'react-day-picker';
import { startOfMonth, subMonths, endOfMonth, format, isToday, differenceInCalendarMonths, parse, isSameMonth } from 'date-fns';

import CalendarPostsModal from './calendar-posts-modal';
import Header, { HeaderProps } from '@/src/components/header/header';
import { Month } from '@/src/models/month.model';
import { Post } from '@/src/models/post.model';

import * as styles from './calendar-posts.module.scss';
import { navigate } from 'gatsby-link';
import { useApi } from '@/src/contexts/api.context';

type CalendarProps = {
  landing?: boolean;
  unauthenticated?: boolean;
  pageContext?: any;
  setHeaderProps?: Function;
};

interface PostMap {
  [monthStr: string]: Post[];
}

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const WEEKDAYS_LONG =
  [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
const WEEKDAYS_SHORT = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

export default function CalendarPosts({ pageContext }: CalendarProps) {
  const apiService = useApi();
  const [translation] = useState(new TranslationService(pageContext));
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 850);
  const defaultHeaderProps: HeaderProps = {
    landing: false,
    unauthenticated: false,
    pageContext: pageContext,
    specialHeaderContent: null,
    goBack: null
  };
  const dateParam = typeof location !== 'undefined' ? new URLSearchParams(location?.search).get('date') : null;
  const defaultDate = dateParam ? parse(dateParam, 'yyyy-MM-dd', new Date()) : null;
  const [headerProps, setHeaderProps] = useState(defaultHeaderProps);
  const monthsToLoadDesktop = 3;
  const defaultMonths: Month[] = [
    { date: startOfMonth(new Date()), data: null },
    { date: subMonths(startOfMonth(new Date()), 1), data: null },
    { date: subMonths(startOfMonth(new Date()), 2), data: null },
  ];

  const [months, setMonths] = useState(defaultMonths);
  const [initialMonthsSet, setInitialMonthsSet] = useState(false);
  const [showModalForDate, setShowModalForDate] = useState(null);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if(isDesktop) {
      handleDesktopTransition();
    }
  }, [isDesktop]);

  const init = async () => {
    window.addEventListener('resize', e => {
      if(window.innerWidth >= 850) {
        setIsDesktop(true);
      } else if(window.innerWidth < 850) {
        setIsDesktop(false);
      }
    });

    setMonths(await loadPostsForMonths(months[months.length - 1].date, endOfMonth(months[0].date)));
    setInitialMonthsSet(true);
  };

  useEffect(() => {
    if(initialMonthsSet) {
      if(dateParam) {
        const curMonth = months[0];
        let monthsToLoad = Math.abs(differenceInCalendarMonths(defaultDate, curMonth.date)) - (months.length - 1);
        // If month has already been loaded we don't have to load more months
        monthsToLoad = monthsToLoad > 0 ? monthsToLoad : 0;

        if(monthsToLoad > 0) {
          next(monthsToLoad);
        }

        setDateModal(defaultDate, months.find(m => isSameMonth(defaultDate, m.date)));
      }
    }
  }, [initialMonthsSet]);

  useEffect(() => {
    // When months is updated check if the latest loaded month has posts and if date param is set and is the same month,
    // because if it matches we should refresh the date modal to display existing posts
    if(months[months.length - 1].data?.posts && typeof location !== 'undefined' && new URLSearchParams(location?.search).get('date')) {
      const dateParam = parse(new URLSearchParams(location?.search).get('date'), 'yyyy-MM-dd', new Date());

      if(isSameMonth(months[months.length - 1].date, dateParam)) {
        setDateModal(dateParam, months[months.length - 1]);
      }
    }
  }, [months]);

  const handleDesktopTransition = () => {
    if(months.length % monthsToLoadDesktop > 0) {
      const monthsToLoad = monthsToLoadDesktop - (months.length % monthsToLoadDesktop);
      next(monthsToLoad);
    }
  };

  const loadMoreDesktopMonths = () => {
    handleDesktopTransition();
    next(monthsToLoadDesktop);
  };

  const setDateModal = (day, month) => {
    setShowModalForDate({ date: day, posts: month?.data?.posts[format(day, 'yyyy-MM-dd')] });
    setHeaderProps({
      landing: false,
      unauthenticated: false,
      pageContext: pageContext,
      specialHeaderContent: null,
      goBack: null
    });
  };
  /**
   * Load posts for a date span. Send in a month to only update posts for it.
   * @param start 
   * @param end 
   * @param month 
   * @returns 
   */
  const loadPostsForMonths = async (start: Date, end: Date, monthsToUpdate?: Month[]) => {
    try {
      // Store posts for a certain month in a map to easily access them based on a known month
      const postMap: PostMap = {};
      const newMonths = [...months];
      const posts = await apiService.getPosts(start, end);

      if(posts) {
        for(const data of posts) {
          const monthStr = format(data.date, 'yyyy-MM');

          if(!postMap[monthStr]) {
            postMap[monthStr] = [];
          }

          postMap[monthStr].push(data);
        }
      }

      if(monthsToUpdate) {
        // Set posts for specific months
        for(const monthToUpdate of monthsToUpdate) {
          populateMonth(monthToUpdate, postMap);
        }
      } else {
        // Set posts for all existing months
        for(const month of newMonths) {
          populateMonth(month, postMap);
        }
      }

      return newMonths;
    } catch(err) {
      console.error(err);
    }
  };

  /**
   * Insert posts belonging to their dates in a month object.
   * @param month 
   * @param postMap 
   */
  const populateMonth = (month: Month, postMap) => {
    const monthStr = format(month.date, 'yyyy-MM');

    if(postMap[monthStr]) {
      if(!month.data) {
        month.data = {};
      }

      for(const post of postMap[monthStr]) {
        if(!month.data.posts) {
          month.data.posts = {};
        }

        if(!month.data.posts[format(post.date, 'yyyy-MM-dd')]) {
          month.data.posts[format(post.date, 'yyyy-MM-dd')] = [];
        }

        month.data.posts[format(post.date, 'yyyy-MM-dd')].push(post);
      }
    }
  };

  /**
   * Loads a new month when scrolling.
   */
  const next = async (monthsToLoad: number = 1) => {
    let earliestMonth: Month, latestMonth: Month;
    const loadedMonths = [];
    for(let i = 0; i < monthsToLoad; i++) {
      const prevMonth = { date: subMonths(months[months.length - 1].date, 1), data: null };
      months.push(prevMonth);
      earliestMonth = prevMonth;

      if(i === 0) {
        latestMonth = prevMonth;
      }

      loadedMonths.push(prevMonth);
    }

    const newMonths = [...months];
    // Insert the empty month into the UI first
    setMonths(newMonths);
    // Insert posts for the month asynchronically to prevent scroll blocking
    setMonths(await loadPostsForMonths(earliestMonth.date, endOfMonth(latestMonth.date), loadedMonths));
  };

  const dayClicked = (day: Date, month: Month, modifiers, event) => {
    event.stopPropagation();

    if(modifiers.disabled) {
      return;
    }
    else {
      setDateModal(day, month);
      navigate(`${translation.appLinkPrefix}/?date=${format(day, 'yyyy-MM-dd')}`, { replace: true });
    }
  };

  function renderDay(day: Date, month: Month) {
    const date = day.getDate();
    const dateStr = format(day, 'yyyy-MM-dd');
    const hasPosts = month?.data?.posts[dateStr];

    return (
      <div className="date-outer" key={dateStr}>
        <div className={(hasPosts ? 'DayPicker-Day--hasPosts' : '') + ' date-wrapper'}>
          {date}
          {isToday(day) ? <span className={styles.todayDot}></span> : ''}
        </div>
      </div>
    );
  }

  function renderCaption({ classNames, date, months, locale, localeUtils, onClick }) {
    return <div className={classNames.caption} role="heading" aria-live="polite">
      <div className="caption-inner" onClick={onClick}>
        <span>{translation.translate('calendar.month.' + format(date, 'LLLL'))}</span> <span>{date.getFullYear()}</span>
      </div>
    </div>;
  }

  function closeModal() {
    setShowModalForDate(null);
    setHeaderProps({
      landing: false,
      unauthenticated: false,
      pageContext: pageContext,
      specialHeaderContent: null,
      goBack: null
    });
  }

  function calendarWrapperClicked(event) {
    if(showModalForDate) {
      closeModal();
    }
  }

  const getDesktopHeading = () => {
    const startingYear = format(startOfMonth(months[months.length - 1].date), 'yyyy');
    const endingYear = format(endOfMonth(months[0].date), 'yyyy');

    return startingYear === endingYear ? endingYear : startingYear + ' - ' + endingYear;
  };

  return (
    <>
      <Header {...headerProps}></Header>
      {
        isDesktop ?
          <div
            className={styles.calendar__desktopWrapper + ' daypicker__wrapper-desktop'}
            onClick={calendarWrapperClicked}>
            <div className={styles.calendar__desktopStepper}>
              <div className={styles.calendar__desktopStepperInner}>
                <span className={styles.calendar__desktopStepperYear}>{getDesktopHeading()}</span>
                <div>
                  <button className={styles.calendar__desktopStepperBtn + ' btn--clean'} type="button" onClick={loadMoreDesktopMonths} aria-label={translation.translate('calendar_posts.show_previous_months')}></button>
                </div>
              </div>
            </div>
            <div className={styles.calendar__desktopInner}>
              {
                [...months].reverse().map(month => {
                  return (
                    <DayPicker
                      key={format(month.date, 'yyyy-MM')}
                      initialMonth={month.date}
                      firstDayOfWeek={1}
                      months={MONTHS.map(month => { return translation.translate('calendar.month.' + month); })}
                      weekdaysLong={WEEKDAYS_LONG.map(weekdayLong => { return translation.translate('calendar.dayLong.' + weekdayLong); })}
                      weekdaysShort={WEEKDAYS_SHORT.map(weekdayShort => { return translation.translate('calendar.dayShort.' + weekdayShort); })}
                      canChangeMonth={false}
                      captionElement={renderCaption}
                      onDayClick={(day, modifiers, event) => dayClicked(day, month, modifiers, event)}
                      renderDay={(day) => renderDay(day, month)}
                      disabledDays={[{ after: new Date() }]}
                    />);
                })
              }
            </div>
          </div>
          :
          <div
            id="calendar-wrapper"
            className={styles.calendar__mobileWrapper}
            onClick={calendarWrapperClicked}
          >
            {/*Put the scroll bar always on the bottom*/}
            <InfiniteScroll
              dataLength={months.length}
              next={next}
              style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
              inverse={true}
              hasMore={true}
              scrollThreshold={0.8}
              loader={<span></span>}
              scrollableTarget="calendar-wrapper"
            >

              {
                months.map(month => {
                  return (
                    <DayPicker
                      key={format(month.date, 'yyyy-MM')}
                      initialMonth={month.date}
                      firstDayOfWeek={1}
                      months={MONTHS.map(month => { return translation.translate('calendar.month.' + month); })}
                      weekdaysLong={WEEKDAYS_LONG.map(weekdayLong => { return translation.translate('calendar.dayLong.' + weekdayLong); })}
                      weekdaysShort={WEEKDAYS_SHORT.map(weekdayShort => { return translation.translate('calendar.dayShort.' + weekdayShort); })}
                      canChangeMonth={false}
                      captionElement={renderCaption}
                      onDayClick={(day, modifiers, event) => dayClicked(day, month, modifiers, event)}
                      renderDay={(day) => renderDay(day, month)}
                      disabledDays={[{ after: new Date() }]}
                    />);
                })
              }
            </InfiniteScroll>
          </div>
      }

      <CalendarPostsModal
        pageContext={pageContext}
        data={showModalForDate}
        close={closeModal}
        setHeaderProps={setHeaderProps} />

      <div className="decoration-circle-wrapper decoration-circle-wrapper--authenticated">
        <div className="decoration-circle">
        </div>
      </div>
    </>
  );
}