import React, { useEffect, useState } from 'react';
import { checkboxRequiredValidator, createFormField } from '@/src/models/form.model';
import { FormError } from '@/src/components/form-error/form-error';
import { TranslationService } from '../../../services/translation.service';
import { useForm } from '@/src/hooks/form.hook';

import { LargeButton } from '@/src/components/button/button';
import Spinner from '@/src/components/spinner/spinner';
import * as styles from './delete-account.module.scss';

export default function DeleteAccountConfirmation({ pageContext, submitting, error, deleteAccount }) {
  const confirmationForm = {
    checkbox: {
      ...createFormField(
        (onInputChange, translation, value, isValid, error, key) => {
          return <>
            <input
              key={key}
              id="checkbox"
              name="checkbox"
              type="checkbox"
              checked={value ? true : false}
              onChange={onInputChange} />
            <label className="checkbox-label">
              <span>{translation.translate('delete_account.consent')}</span>
              <span className={styles.star}>*</span>
            </label>
            <FormError valid={isValid} message={error}></FormError>
          </>;
        }
      ),
      validationRules: [
        checkboxRequiredValidator()
      ]
    },
    textField: {
      ...createFormField(
        (onInputChange, translation, value, isValid, error, key) => {
          return <>
            <input
              key={key}
              id={'textField'}
              name={'textField'}
              placeholder={translation.translate('delete_account.feedback_placeholder')}
              type="text"
              value={value ? value : ''}
              onChange={onInputChange}
            />
            <FormError valid={isValid} message={error}></FormError>
          </>;
        }
      ),
      validationRules: [
      ]
    }
  };

  const [translation] = useState(new TranslationService(pageContext));
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const { renderInput, isFormValid, touchForm, form } = useForm(translation, confirmationForm);

  const submit = (event: React.FormEvent) => {
    event.preventDefault();
    touchForm();
    setSubmitAttempted(true);
    return false;
  };

  useEffect(() => {
    if(submitAttempted) {
      if(isFormValid()) {
        deleteAccount(form);
      }

      setSubmitAttempted(false);
    }
  }, [submitAttempted]);

  return (
    <>
      <form className="form" onSubmit={submit} autoComplete="off">

        <h1>{translation.translate('delete_account.delete_your_account')}</h1>
        <div>
          <p className="auth__description">{translation.translate('delete_account.info')}</p>
          <h4 className={styles.deleteConfirmationHeading}>{translation.translate('delete_account.motivation')}</h4>
          <div >
            {renderInput('textField')}
          </div>

          <div>
            <p className={styles.deleteConfirmationDescription}>{translation.translate('delete_account.delete_disclamer')}</p>
            <div className={styles.checkboxWrapper} >
              {renderInput('checkbox')}
            </div>
          </div>

          {error ?
            <div className={styles.deleteError + ' form__error-message'} dangerouslySetInnerHTML={{ __html: error }}></div> : null}
        </div>
        <div className="form__group form__group--submit">
          <LargeButton type="submit" loading={submitting}>
            <span>{translation.translate('profile.delete_account')}</span>
          </LargeButton>
        </div>
      </form>

    </>
  );
}