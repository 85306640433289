import { TranslationProvider } from "@/src/contexts/translation.context";
import { TranslationService } from "@/src/services/translation.service";
import { PageProps } from "gatsby";
import { Helmet } from "react-helmet";
import React, { useState } from "react";
import CalendarPosts from "../../../components/calendar-posts/calendar-posts";
import { Router } from "@reach/router";
import PrivateRoute from "@/src/components/private-route/private-route";
import Post from "../post/post";
import Profile from "../profile/profile";
import SendReport from "../send-report/send-report";
import Posts from "../posts/posts";
import DeleteAccount from "../delete-account/delete-account";
import AccountWillBeDeleted from "../account-will-be-deleted/account-will-be-deleted";

export default function IndexPage(pageProps: PageProps) {
  const [translation] = useState(new TranslationService(pageProps.pageContext));

  return (
    <TranslationProvider value={translation}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{translation.translate("index.title")}</title>
        <meta
          name="description"
          content={translation.translate("index.description")}
        />
        <meta http-equiv="content-language" content={translation.lang} />
        <html className="authenticated"></html>
      </Helmet>

      <Router basepath={translation.appLinkPrefix}>
        <PrivateRoute
          path="/"
          translation={translation}
          default={true}
          component={CalendarPosts}
          componentProps={pageProps}
        />
        <PrivateRoute
          path="/post"
          translation={translation}
          component={Post}
          componentProps={pageProps}
        />
        <PrivateRoute
          path="/posts/:dateParam"
          translation={translation}
          component={Posts}
          componentProps={pageProps}
        />
        <PrivateRoute
          path="/profile"
          translation={translation}
          component={Profile}
          componentProps={pageProps}
        />
        <PrivateRoute
          path="/send-report"
          translation={translation}
          component={SendReport}
          componentProps={pageProps}
        />
        <PrivateRoute
          path="/delete-account"
          translation={translation}
          component={DeleteAccount}
          componentProps={pageProps}
        />
        <PrivateRoute
          path="/account-will-be-deleted"
          translation={translation}
          component={AccountWillBeDeleted}
          componentProps={{ ...pageProps, isAccountWillBeDeletedRoute: true }}
        />
      </Router>
    </TranslationProvider>
  );
}
