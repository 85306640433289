import React, { useEffect, useState } from 'react';
import Control from '@/assets/icons/quick-log/control.inline.svg';
import Financial from '@/assets/icons/quick-log/financial.inline.svg';
import Isolation from '@/assets/icons/quick-log/isolation.inline.svg';
import Physical from '@/assets/icons/quick-log/physical.inline.svg';
import Psychological from '@/assets/icons/quick-log/psychological.inline.svg';
import Sexual from '@/assets/icons/quick-log/sexual.inline.svg';
import Threat from '@/assets/icons/quick-log/threat.inline.svg';
import Verbal from '@/assets/icons/quick-log/verbal.inline.svg';
import { Media, Post } from '@/src/models/post.model';
import { PageProps } from 'gatsby';
import { TranslationService } from '@/src/services/translation.service';
import { getFileTypeIcon, getMediaType } from '@/src/helpers/file-types';
import * as styles from './post-list-item.module.scss';
import format from 'date-fns/format';
import Spinner from '../spinner/spinner';
import { useApi as useApi } from '@/src/contexts/api.context';

type PostListProps = {
  pageContext: PageProps['pageContext'];
  post?: Post;
  media?: Media;
};

export default function PostListItem({ pageContext, post, media }: PostListProps) {
  const apiService = useApi();
  const [translation] = useState(new TranslationService(pageContext));
  const [mediaUrl, setMediaUrl] = useState('');
  const [loadingMedia, setLoadingMedia] = useState(false);
  const quickIconList = [
    {
      key: 'control',
      icon: Control
    },
    {
      key: 'financial',
      icon: Financial
    },
    {
      key: 'isolation',
      icon: Isolation
    },
    {
      key: 'physical',
      icon: Physical
    },
    {
      key: 'psychological',
      icon: Psychological
    },
    {
      key: 'sexual',
      icon: Sexual
    },
    {
      key: 'threat',
      icon: Threat
    },
    {
      key: 'verbal',
      icon: Verbal
    },
  ];

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    if(media) {
      if(media.contentType.startsWith('image/')) {
        setLoadingMedia(true);
        setMediaUrl(await apiService.getSignedMediaUrl(media.path));
        setLoadingMedia(false);
      } else {
        setMediaUrl(getFileTypeIcon(media.contentType));
      }
    }
  };

  return (
    <div className={styles.listItemWrapper}>
      {
        <div className={styles.postWrapper}>
          {
            !media ?
              <>
                <div className={styles.postHeader}>
                  <h2 className={styles.postHeading}>
                    {translation.translate('note')}
                    {post.tags?.map((tag, i) => {
                      const quickIcon = quickIconList.filter((item) => {
                        if(item.key === tag) {
                          return item;
                        }
                      });
                      const Icon = quickIcon[0].icon;

                      return (
                        <div className={styles.postTag} key={i}>
                          <Icon />
                        </div>
                      );
                    })}
                  </h2>
                  {post.timeChosen ? <span className={styles.time}>{translation.translate('post_list.time_at') + ' ' + format(post.date, 'HH:mm')}</span> : ''}
                </div>
                <p className={styles.postText}>{post.text}</p>
              </>
              :
              <>
                <div className={styles.postMediaHeader}>
                  <div className={styles.preview}>
                    {
                      loadingMedia ?
                        <Spinner dark={true} /> :
                        <img
                          src={mediaUrl}
                          alt={media.originalName} />
                    }
                  </div>

                  <div className={styles.headerRight}>
                    <div className={styles.headerRightTop}>
                      <h2>{translation.translate(getMediaType(media.contentType))}</h2>
                      {post.timeChosen ? <span className={styles.time}>{translation.translate('post_list.time_at') + ' ' + format(post.date, 'HH:mm')}</span> : ''}
                    </div>
                    <div>
                      {media.originalName}
                    </div>
                  </div>
                </div>
              </>
          }
        </div>
      }
    </div>
  );
}