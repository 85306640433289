import { TranslationService } from '@/src/services/translation.service';
import React, { useEffect, useState } from 'react';
import { navigate, PageProps } from 'gatsby';
import format from 'date-fns/format';
import Header from '@/src/components/header/header';
import { endOfDay, parse } from 'date-fns';
import Plus from '@/assets/icons/plus.inline.svg';
import Spinner from '@/src/components/spinner/spinner';
import PostList from '@/src/components/post-list/post-list';
import { LargeButton } from '@/src/components/button/button';
import * as styles from './posts.module.scss';
import { useApi } from '@/src/contexts/api.context';

type PostsProps = PageProps & {
  dateParam: string;
};

export default function Posts({ pageContext, dateParam }: PostsProps) {
  const apiService = useApi();
  const [translation] = useState(new TranslationService(pageContext));
  const date = parse(dateParam, 'yyyy-MM-dd', new Date());
  const [posts, setPosts] = useState([]);
  const [postsLoading, setPostsLoading] = useState(false);
  const [navigatingToAdd, setNavigatingToAdd] = useState(false);

  useEffect(() => {
    getPosts(date, endOfDay(date));
  }, []);

  const getPosts = async (start: Date, end: Date) => {
    setPostsLoading(true);
    setPosts(await apiService.getPosts(start, end, -1));
    setPostsLoading(false);
  };

  const navigateToAdd = () => {
    setNavigatingToAdd(true);
    navigate(`${translation.appLinkPrefix}/post${date ? `?date=${format(date, 'yyyy-MM-dd')}` : ''}`);
  };

  return (
    <>
      <Header unauthenticated={false}
        pageContext={pageContext}
        specialHeaderContent={translation.translate('calendar.dayLong.' + format(date, 'EEEE')) + ' ' +
          format(date, 'dd.MM')}
        goBack={() => {
          history.back();
        }} />

      {
        postsLoading ? <Spinner dark={true}></Spinner> :
          <>
            <PostList pageContext={pageContext} posts={posts} date={format(date, 'EEEE dd.MM')} />
            <div className={styles.addButtonWrapper}>
              <LargeButton type="button" loading={navigatingToAdd} onClick={navigateToAdd}>
                <div className="content">
                  <span>{translation.translate('add')}</span>
                  <Plus></Plus>
                </div>
              </LargeButton>
            </div>
          </>
      }
    </>
  );
};