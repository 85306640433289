// extracted by mini-css-extract-plugin
export var circle = "post-list-item-module--circle--SqbXX";
export var headerRight = "post-list-item-module--headerRight--qxzQa";
export var headerRightTop = "post-list-item-module--header-right-top--WR4bJ";
export var listItemWrapper = "post-list-item-module--list-item-wrapper--sHxQB";
export var postHeader = "post-list-item-module--post-header--ERdjO";
export var postHeading = "post-list-item-module--post-heading--ekHM4";
export var postMediaHeader = "post-list-item-module--post-media-header--PBQEd";
export var postTag = "post-list-item-module--post-tag--E3bHN";
export var postText = "post-list-item-module--post-text--zl3UW";
export var postWrapper = "post-list-item-module--post-wrapper--ko-N8";
export var preview = "post-list-item-module--preview--PODmf";
export var time = "post-list-item-module--time--oy3Qg";